import Cookie from 'js.cookie';
export default {
  init() {
    // JavaScript to be fired on all pages
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired

    // toggles hamburger and nav open and closed states
    var removeClass = true;
    $('.hamburger').click(function () {
      $('.hamburger').toggleClass('is-active');
      $('#sideNav').toggleClass('sideNav-open');
      $('.sideNavBody').toggleClass('sideNavBody-push');
      removeClass = false;
    });

    $('.sideNav').click(function() {
      removeClass = false;
    });

    document.addEventListener('touchstart', function(e) {
      if (removeClass && !$(e.target).hasClass('sideNav') && $('.sideNav').has($(e.target)).length === 0) {
        $('.hamburger').removeClass('is-active');
        $('#sideNav').removeClass('sideNav-open');
        $('.sideNavBody').removeClass('sideNavBody-push');
      }
      removeClass = true;
    }, false);

    $(window).scroll(function () {
      if ($(this).scrollTop() > 100) {
        $('.scrollup').fadeIn();
      } else {
        $('.scrollup').fadeOut();
      }
    });
    
    $('.scrollup').click(function () {
      $('html, body').animate({
        scrollTop: 0,
      }, 600);
      return false;
    });

    if (!Cookie.get('alert') && $('#modal').length) {
      $('#modal').show();
    }
    
    $('.modal .btn-close').click(function() {
      $('#modal').hide();
      Cookie.set('alert', true, {
        expires: 1,
      });
    });

    $('img').parent('a').addClass('no-external-link');
    $('i').parent('a').addClass('no-external-link');

    /* serves hero image variant based on breakpoint
    *  needs cloudflare cdn on div with hero class
    */
    $(window).resize(function() {
      
      if ($(window).width() < 1600) {
         $('.hero.cloudflare').attr('style', function(_, value) {
           return value.replace('/1920px', '/1600px');
         });
       } else {
         $('.hero.cloudflare').attr('style', function(_, value) {
           return value.replace('/1600px', '/1920px');
         });
       }
       
      if ($(window).width() < 1440) {
         $('.hero.cloudflare').attr('style', function(_, value) {
           return value.replace('/1600px', '/1440px');
         });
       } else {
         $('.hero.cloudflare').attr('style', function(_, value) {
           return value.replace('/1440px', '/1600px');
         });
       }
       
      if ($(window).width() < 1200) {
         $('.hero.cloudflare').attr('style', function(_, value) {
           return value.replace('/1440px', '/1200px');
         });
       } else {
         $('.hero.cloudflare').attr('style', function(_, value) {
           return value.replace('/1200px', '/1440px');
         });
       }
       
      if ($(window).width() < 992) {
         $('.hero.cloudflare').attr('style', function(_, value) {
           return value.replace('/1200px', '/992px');
         });
       } else {
         $('.hero.cloudflare').attr('style', function(_, value) {
           return value.replace('/992px', '/1200px');
         });
       }
       
       if ($(window).width() < 768) {
           $('.hero.cloudflare').attr('style', function(_, value) {
             return value.replace('/992px', '/768px');
           });
         } else {
           $('.hero.cloudflare').attr('style', function(_, value) {
             return value.replace('/768px', '/992px');
           });
         }
 
         if ($(window).width() < 576) {
           $('.hero.cloudflare').attr('style', function(_, value) {
             return value.replace('/768px', '/576px');
           });
         } else {
           $('.hero.cloudflare').attr('style', function(_, value) {
             return value.replace('/576px', '/768px');
           });
         }
     }).trigger('resize');

     if ($('.testimonials-carousel').length) {
      $('.testimonials-carousel').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        appendArrows: '.testimonials-carousel',
        appendDots: '.testimonials-carousel',
        adaptiveHeight: true,
        arrows: true,
        dots: false,
        fade: true,
        autoplay: true,
        autoplaySpeed: 4000,
        speed: 1200,
        responsive: [
          {
            breakpoint: 1023,
            settings: {
              autoplay: false,
            },
          },
        ],
      });
    }
  },
};
